import React from "react"
import { Helmet } from 'react-helmet'
import '../components/global.css'

export default function Home() {
    return (
	<>
	    <Helmet>
		<link rel="preconnect" href="https://fonts.gstatic.com" />
		<link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39+Extended+Text&display=swap" rel="stylesheet" />
	    </Helmet>
	    <>
 		<div style={{ backgroundColor: 'black' , display: 'grid' , placeItems: 'center' , height: '100vh' }}>
		    <div style={{ color: 'white' , fontSize: '64px' }}>
			finite con
		    </div>
	    	</div>
	    </>
	</>
    )
}

